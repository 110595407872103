// import { format, formatDistance, differenceInDays } from 'date-fns';
import React from 'react';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import BlockContent from './block-content';
import Container from './layout/container';
import ContentSections from '../components/pagebuilder/content-sections';

import {
  root,
  title as titleCss,
  subtitle as subtitleCss,
  mainImage,
  metaContent,
  metaItemWrapper,
  metaTitle,
  metaDescription,
  horizontalLine,
  blogContent,
} from './blog-post.module.css';
import { bgHeader } from './layout/layout.module.css';

const BlogPost = (props) => {
  const { _rawBody, authors, categories, title, subtitle, mainImage, publishedAt, content } = props;
  return (
    <>
      <div id="section-hero" className={bgHeader} />
      <article className={root}>
        <Container>
          <div>
            {title && <h1 className={titleCss}>{title}</h1>}
            {subtitle && <h4 className={subtitleCss}>{subtitle}</h4>}
          </div>

          {mainImage && mainImage.asset && (
            <div className={mainImage}>
              <img
                src={imageUrlFor(buildImageObj(mainImage))
                  .width(1200)
                  .height(Math.floor((9 / 16) * 1200))
                  .fit('crop')
                  .auto('format')
                  .url()}
                alt={mainImage.alt}
              />
            </div>
          )}
          <div>
            <div className={metaContent}>
              {/*{publishedAt && (*/}
              {/*  <div className={metaItemWrapper}>*/}
              {/*    <span className={metaTitle}>Posted On －</span>*/}
              {/*    <div className={metaDescription}>*/}
              {/*      {differenceInDays(new Date(publishedAt), new Date()) > 3*/}
              {/*        ? formatDistance(new Date(publishedAt), new Date())*/}
              {/*        : format(new Date(publishedAt), 'MMM do, yyyy')}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
              {authors.length > 0 && (
                <div className={metaItemWrapper}>
                  <span className={metaTitle}>Words By －</span>
                  <span className={metaDescription}>{authors.map((item) => item.author.name).join(', ')}</span>
                </div>
              )}

              {/*{categories.length > 0 && (*/}
              {/*  <div className={metaItemWrapper}>*/}
              {/*    <span className={metaTitle}>Categories －</span>*/}
              {/*    <span className={metaDescription}>{categories.map((item) => item.title).join(', ')}</span>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </div>

          <hr className={horizontalLine} />

          {_rawBody && (
            <div className={blogContent}>
              <BlockContent blocks={_rawBody} />
            </div>
          )}
          <div>{content && <ContentSections content={content} />}</div>
        </Container>
      </article>
    </>
  );
};

export default BlogPost;
