// extracted by mini-css-extract-plugin
export var base = "blog-post-module--base--eD+ET";
export var blockQuote = "blog-post-module--blockQuote--HA5U1";
export var blogContent = "blog-post-module--blogContent--p6ip3";
export var categories = "blog-post-module--categories--AH481";
export var categoriesHeadline = "blog-post-module--categoriesHeadline--aWVrh typography-module--base--yrof+";
export var grid = "blog-post-module--grid--QOScI";
export var horizontalLine = "blog-post-module--horizontalLine--Vx8kZ";
export var large = "blog-post-module--large--2rwrf";
export var mainContent = "blog-post-module--mainContent--eLO5g";
export var mainImage = "blog-post-module--mainImage--IMMYf";
export var metaContent = "blog-post-module--metaContent--nKBLn";
export var metaDescription = "blog-post-module--metaDescription--fWB9s";
export var metaItemWrapper = "blog-post-module--metaItemWrapper--IZLdr typography-module--base--yrof+";
export var metaTitle = "blog-post-module--metaTitle--l-UkN";
export var micro = "blog-post-module--micro--CKn9v";
export var paragraph = "blog-post-module--paragraph--Xj7bA";
export var publishedAt = "blog-post-module--publishedAt---ggFA typography-module--small--CJ-EH";
export var responsiveTitle1 = "blog-post-module--responsiveTitle1--8ggI6";
export var responsiveTitle2 = "blog-post-module--responsiveTitle2--85Ole";
export var responsiveTitle3 = "blog-post-module--responsiveTitle3--RtsFI";
export var responsiveTitle4 = "blog-post-module--responsiveTitle4--pC8PV";
export var responsiveTitle5 = "blog-post-module--responsiveTitle5--PSLC-";
export var responsiveTitleHuge = "blog-post-module--responsiveTitleHuge--ZVGR4";
export var root = "blog-post-module--root--wx77T";
export var small = "blog-post-module--small--zTvNh";
export var subtitle = "blog-post-module--subtitle--t-A9U typography-module--responsiveTitle4--z-i93";
export var title = "blog-post-module--title--9dCoJ typography-module--responsiveTitle2--ySzQe";
export var title1 = "blog-post-module--title1--jI5mF";
export var title2 = "blog-post-module--title2--gxAmi";
export var title3 = "blog-post-module--title3--HSOiJ";